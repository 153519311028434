/** @jsx jsx */
import { jsx, ClassNames } from "@emotion/react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import theme from "../themes";

import { mq, typography, colors } from "./styles";
import { useTranslation } from "./translation";

const El = styled(Link)({
  "&.activeLink": {
    color: theme.primary,
  },
  ":hover": {
    backgroundColor: "rgba(55, 53, 47, 0.08)",
  },
  borderRadius: "0.4rem",
  color: theme.textSurfaceHigh,
  display: "none",
  fontFamily: "Source Sans Pro, sans-serif",
  fontSize: "1.1rem",
  fontWeight: "600",
  margin: "0 0.2rem 0 0",
  [mq[1]]: {
    display: "block",
  },
  padding: "0.4rem 0.8rem",
  transition: "background 120ms ease-in 0s",
});

const DemoButton = styled(El)({
  "&.activeLink": {
    ":hover": {
      backgroundColor: "rgba(55, 53, 47, 0.08)",
    },
    backgroundColor: "white",
    boxShadow: `0 0 0 2px ${colors.strong} inset`,
  },
  ":hover": {
    backgroundColor: theme.primaryDark,
  },
  backgroundColor: theme.primary,
  color: theme.textPrimaryHigh,
  display: "none",
  [mq[1]]: { display: "block" },
});

const Select = styled("select")({
  backgroundColor: "transparent",
  border: "1px solid rgba(55, 53, 47, 0.08)",
  borderRadius: "0.4rem",
  color: "#000",
  fontFamily: "Source Sans Pro, sans-serif",
  padding: "0.4rem 0.8rem",
});

const LanguageSwitcher = () => {
  const { language, setLanguage } = useTranslation();

  const onChangeLanguage = (e) => setLanguage(e.target.value);

  return (
    <>
      <Select
        name="language"
        id="language"
        value={language}
        onChange={onChangeLanguage}
        css={{
          [mq[1]]: { display: "none" },
          [mq[2]]: { display: "block" },
        }}
      >
        <option value="en">English</option>
        <option value="fr">Français</option>
      </Select>

      <Select
        name="language"
        id="language"
        value={language}
        onChange={onChangeLanguage}
        css={{
          display: "none",
          [mq[1]]: { display: "block" },
          [mq[2]]: { display: "none" },
        }}
      >
        <option value="en">EN</option>
        <option value="fr">FR</option>
      </Select>
    </>
  );
};

const _strings = {
  en: {
    appName: "Gaston",
    demo: "Request a Demo",
    home: "Home",
    mission: "Our Mission",
    pricing: "Pricing",
  },
  fr: {
    appName: "Gaston",
    demo: "Demander une démo",
    home: "Accueil",
    mission: "Notre mission",
    pricing: "Tarifs",
  },
};

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { strings } = useTranslation(_strings);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "scroll";
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        css={{
          backgroundColor: "transparent",
          border: 0,
          [mq[0]]: { display: "none" },
        }}
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon
          icon="bars"
          css={{
            color: colors.strong,
            margin: "4px 0 0",
          }}
        />
      </button>
      <ClassNames>
        {({ css }) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={120}
            className={css({
              maxHeight: "100vh",
              outline: "none",
              padding: "1rem",
            })}
            overlayClassName={{
              afterOpen: css({ opacity: "1 !important" }),
              base: css({
                backgroundColor: "#FFF",
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                left: 0,
                opacity: 0,
                overflow: "scroll",
                position: "fixed",
                right: 0,
                top: 0,
                transition: "opacity 120ms ease-in-out 0s",
              }),
              beforeClose: css({ opacity: "0 !important" }),
            }}
          >
            <button
              type="button"
              css={{
                alignItems: "center",
                backgroundColor: "#FFF",
                border: "none",
                borderRadius: "50%",
                boxShadow: "0px 0px 8px 0 rgba(0,0,0,0.2)",
                color: "#000",
                cursor: "pointer",
                display: "flex",
                height: "2.4rem",
                justifyContent: "center",
                position: "absolute",
                right: "1rem",
                top: "1rem",
                width: "2.4rem",
              }}
              onClick={() => setIsOpen(false)}
            >
              <FontAwesomeIcon icon="times" />
            </button>
            <div
              css={{
                borderBottom: "1px solid #E5E5E5",
                marginBottom: "0.4rem",
                paddingBottom: "0.4rem",
              }}
            >
              <El
                css={{ display: "block" }}
                activeClassName="activeLink"
                to="/"
              >
                {strings.home}
              </El>
            </div>
            <div
              css={{
                borderBottom: "1px solid #E5E5E5",
                marginBottom: "0.4rem",
                paddingBottom: "0.4rem",
              }}
            >
              <El
                css={{ display: "block" }}
                activeClassName="activeLink"
                to="/pricing"
              >
                {strings.pricing}
              </El>
            </div>
            <div
              css={{
                borderBottom: "1px solid #E5E5E5",
                marginBottom: "0.4rem",
                paddingBottom: "0.4rem",
              }}
            >
              <El
                css={{ display: "block" }}
                activeClassName="activeLink"
                to="/team"
              >
                {strings.mission}
              </El>
            </div>
            <div
              css={{
                marginBottom: "0.4rem",
                paddingBottom: "0.4rem",
              }}
            >
              <El
                css={{ display: "block" }}
                activeClassName="activeLink"
                to="/demo"
              >
                {strings.demo}
              </El>
            </div>
            <div>
              <LanguageSwitcher />
            </div>
          </Modal>
        )}
      </ClassNames>
    </>
  );
};

const Header = () => {
  const { strings } = useTranslation(_strings);

  return (
    <header>
      <div
        css={{
          alignItems: "center",
          borderBottom: "1px solid #E5E5E5",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0 1rem 1rem",
          padding: "1rem",
          [mq[1]]: {
            margin: "0 11vw 1rem",
          },
        }}
      >
        <div
          css={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Hamburger />
          <Link
            to="/"
            css={{
              ...typography.serif,
              color: theme.primary,
              fontSize: "1.4rem",
              fontWeight: "900",
            }}
          >
            {strings.appName}
          </Link>
        </div>
        <nav
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <El to="/" activeClassName="activeLink">
            {strings.home}
          </El>
          <El to="/pricing" activeClassName="activeLink">
            {strings.pricing}
          </El>
          <El to="/team" activeClassName="activeLink">
            {strings.mission}
          </El>
          <DemoButton to="/demo" activeClassName="activeLink">
            {strings.demo}
          </DemoButton>
          <div
            css={{
              display: "none",
              [mq[1]]: { display: "block" },
            }}
          >
            <LanguageSwitcher />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
